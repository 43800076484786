const ROOT_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_HOST : "";

const BASE_ENDPOINT = `${ROOT_URL}/api/countries`;

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const getCountries = async () => {
  const response = await fetch(`${BASE_ENDPOINT}/`, config);
  const data = response.json();
  return data;
};
