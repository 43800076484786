import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { login } from "../services/auth";
import { useAuth } from "../context/Provider";
import CustomSnackbar from "../components/CustomSnackbar";
import Footer from "../components/Footer";
import { Switch, InputAdornment, IconButton } from "@mui/material";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

function Login() {
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(prev => !prev);
  };

  const handleSubmit = async e => {
    try {
      e.preventDefault();
      const response = await login({ email, password });
      if (!response.token) throw Error(response.message);
      localStorage.setItem("salto_maps_jwt", response.token);
      setIsAuthenticated(true);
    } catch (error) {
      setError(error.message);
      setOpenSnackBar(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard", { replace: true });
  }, [isAuthenticated]);

  return (
    <>
      {error && (
        <CustomSnackbar
          type='error'
          message={error}
          open={openSnackBar}
          handleClose={handleClose}
        />
      )}
      <Grid container component='main' sx={{ height: "93vh" }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={8}
            md={7}
            component={Paper}
            elevation={6}
            square
            display='flex'
            justifyContent='center'
            boxShadow='none'
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                justifyContent: "center",
                maxWidth: "360px",
              }}
            >
              <Typography
                component='h1'
                variant='h5'
                align='left'
                color='#1D4D64'
              >
                Login
              </Typography>
              <Box
                component='form'
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 1 }}
              >
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  label='Email Address'
                  autoComplete='email'
                  autoFocus
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <TextField
                  margin='normal'
                  required
                  fullWidth
                  label='Password'
                  type={showPassword ? "text" : "password"}
                  autoComplete='current-password'
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {" "}
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={handleShowPassword}
                        >
                          {" "}
                          {showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}{" "}
                        </IconButton>{" "}
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign In
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={false}
            sm={4}
            md={5}
            sx={{
              backgroundImage: "url(assets/img/BACKGROUND_IMG.svg)",
              backgroundRepeat: "no-repeat",
              backgroundColor: "#0095DA",
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "flex",
            }}
            justifyContent='center'
            alignItems='center'
          >
            <Box
              display={{ xs: "none", md: "block" }}
              component='img'
              sx={{
                height: 160,
              }}
              alt='Your logo.'
              src={"assets/img/LOGO_SALTO_WHITE.svg"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
}

export default Login;
