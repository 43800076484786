import Box from "@mui/material/Box";

import Map from "../components/Map";
import Navbar from "../components/Navbar";

import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

function Home() {
  return (
    <div
      style={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <Navbar />
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
        }}
        maxHeight={{md:"80.9vh"}}
        flexWrap={{ md: "nowrap" }}
        flexDirection={{ xs: "column", md: "row" }}
        alignItems='center'
        justifyContent='center'
      >
        <Sidebar />
        <Map />
      </Box>
      <Footer />
    </div>
  );
}

export default Home;
