import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

function CustomModal({ handleClose, open, children }) {
  return (
    <Modal
      sx={{
        overflow: 'auto',
        width:{md: '50%', xs: '100%'},
        margin:'60px auto 60px auto',
        borderRadius: "10px",
        }}
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box
        sx={{
          position: "relative",
          transform: "none",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "10px",
          p: 3,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}

export default CustomModal;
