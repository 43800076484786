import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import { downloadAgentVCard } from "../services/agents";

import { downloadFile } from "../utils";

function Slide({ currentSlide, slideData, handleClick }) {
  const handleDownload = async () => {
    try {
      const vcardData = await downloadAgentVCard(slideData);
      downloadFile(
        vcardData,
        `${slideData?.firstName}_${slideData?.lastName}.vcf`
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        minWidth: 275,
        backgroundColor: currentSlide ? "#0192CF" : "#f3f5f7",
        borderRadius: "10px",
        marginBottom: "15px",
      }}
    >
      <CardContent sx={{ color: currentSlide ? "white" : "#718792" }}>
        <Typography
          sx={{ fontSize: 16, fontWeight: "normal" }}
          gutterBottom
          fontWeight='700'
        >
          {slideData.category}
        </Typography>
        <Typography variant='body' fontFamily='Roboto' fontWeight='300'>
          {`${slideData?.firstName} ${slideData?.lastName}`}
        </Typography>
        <br />
        <span style={{ display: "flex", alignItems: "center" }}>
          <Typography variant='body' fontFamily='Roboto' fontWeight='300'>
            {slideData?.company}
          </Typography>
        </span>
        <span style={{ display: "flex", alignItems: "center" }}>
          <Typography variant='body' fontFamily='Roboto' fontWeight='300'>
            {slideData?.title}
          </Typography>
        </span>
        <span style={{ display: "flex", alignItems: "center" }}>
          <Typography variant='body' fontFamily='Roboto' fontWeight='300'>
            {slideData?.email}
          </Typography>
        </span>
        <span style={{ display: "flex", alignItems: "center" }}>
          <Typography variant='body' fontFamily='Roboto' fontWeight='300'>
            {slideData?.phone}
          </Typography>
        </span>
        <span style={{ display: "flex", alignItems: "center" }}>
          <Typography variant='body' fontFamily='Roboto' fontWeight='300'>
            {slideData?.mobilePhone}
          </Typography>
        </span>
        <span style={{ display: "flex", alignItems: "center" }}>
          <Typography variant='body' fontFamily='Roboto' fontWeight='300'>
            {slideData?.website}
          </Typography>
        </span>
        <span style={{ display: "flex", alignItems: "center" }}>
          <Typography variant='body' fontFamily='Roboto' fontWeight='300'>
            {slideData?.officeLocation}
          </Typography>
        </span>
        <br></br>
        {slideData?.notes && (
          <Typography
            variant='body'
            fontFamily='Roboto'
            fontWeight='300'
            style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}
          >
            {slideData?.notes}
          </Typography>
        )}
      </CardContent>
      <CardActions sx={{ justifyContent: "space-between" }}>
        <Button
          size='small'
          sx={{ color: currentSlide ? "white" : "#718792" }}
          component='a'
          onClick={handleDownload}
          target='_blank'
        >
          Share Contact
        </Button>
        <IconButton
          sx={{ color: currentSlide ? "white" : "#718792" }}
          component='a'
          href={`mailto:${slideData?.email}`}
        >
          <EmailOutlinedIcon fontSize='12' />
        </IconButton>
      </CardActions>
    </Card>
  );
}

export default Slide;
