import { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Dropdown from "./Dropdown";
import CustomSlider from "./CustomSlider";

import { useArea } from "../context/Provider";
import { getResourceCategories } from "../services/resources";
import { getAgentsByCountry } from "../services/agents.js";
import { getCountries } from "../services/countries";

function Sidebar() {
  const { country, toogleCountry, area, setSelectedAreas, setAreaSelect } =
    useArea();

  const navigate = useNavigate();

  const [countryOptions, setCountryOptions] = useState([]);

  const [resourceCategory, setResourceCategory] = useState(0);
  const [resourceCategories, setResourceCategories] = useState([]);

  const [agents, setAgents] = useState([]);

  const handleCountrySelection = e => {
    toogleCountry(e.target.value);
  };

  const handleResourceSelection = e => {
    setResourceCategory(e.target.value);
  };

  const getData = useCallback(async () => {
    const countries = await getCountries();
    setCountryOptions(() =>
      countries.map(countryOption => ({
        label: countryOption.name,
        value: countryOption.id,
      }))
    );

    const resources = await getResourceCategories();
    setResourceCategories(() =>
      resources.map(resourceCategory => ({
        label: resourceCategory.category,
        value: resourceCategory.id,
      }))
    );
  }, []);

  const getAgentsData = useCallback(async () => {
    const data = await getAgentsByCountry(country, resourceCategory, area.code);
    setAgents(() => data);
  }, [resourceCategory, area, country]);

  const handleOnClick = () => {
    navigate("/");
    window.location.reload(false);
  };

  useEffect(() => {
    getData();
    getAgentsData();
  }, [getData, getAgentsData]);

  useEffect(() => {
    setSelectedAreas([area.code]);
  }, [resourceCategory, area, country]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
      }}
      padding={{ md: 2 }}
      height={{ md: "816px" }}
      backgroundColor={{ md: "#FBFCFE", xs: "#edf0f4" }}
      maxWidth={{ md: "300px", xs: "92%" }}
      marginTop={{ md: "0px", xs: "20px" }}
      maxHeight={{ md: "78vh" }}
    >
      <Grid
        container
        spacing={2}
        justifyContent='space-between'
        alignItems='center'
        flexDirection='row'
      >
        <Grid item flexGrow={1}>
          <Dropdown
            handler={handleResourceSelection}
            items={resourceCategories}
            value={resourceCategory || ""}
            label='Resource category'
            labelId='resource-category-sidebar'
            internalProps={{
              disableUnderline: true,
              IconComponent: KeyboardArrowDownIcon,
              sx: {
                borderRadius: "10px",
                color: "#1D4D64",
                transition: "none",
              },
            }}
          />
        </Grid>
        <Grid item flexGrow={0.2}>
          <Dropdown
            handler={handleCountrySelection}
            items={countryOptions}
            value={country}
            label='Country'
            labelId='country-sidebar'
            internalProps={{
              disableUnderline: true,
              IconComponent: KeyboardArrowDownIcon,
              sx: {
                borderRadius: "10px",
                color: "#1D4D64",
                transition: "none",
              },
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            size='small'
            sx={{ color: "#718792", mr: 8 }}
            component='a'
            onClick={handleOnClick}
            underline='none'
          >
            Reset All Filters
          </Button>
        </Grid>
      </Grid>
      <CustomSlider items={agents} />
    </Box>
  );
}

export default Sidebar;
