import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import MapIcon from "@mui/icons-material/Map";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LoginIcon from "@mui/icons-material/Login";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import { useAuth } from "../context/Provider";

function Navbar() {
  const [anchorEl, setAnchorEl] = useState();
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = route => {
    navigate(route);
  };

  const handleLogout = () => {
    localStorage.removeItem("salto_maps_jwt");
    setIsAuthenticated(false);
  };

  // redirect to map and reload
  
  const handleOnClick = () => {
    navigate("/");
    window.location.reload(false);
  }

  return (
    <Box>
      <AppBar
        sx={{
          backgroundColor: "#fff",
          padding: "20px",
        }}
        position='static'
        elevation={0}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <Box
            component='img'
            sx={{
              height: 50,
            }}
            alt='Your logo.'
            src={"assets/img/LOGO_SALTO.svg"}
            onClick={handleOnClick}
          />
          {isAuthenticated && (
            <Box>
              {location.pathname === "/dashboard" ? (
                <IconButton onClick={() => handleRedirect("/")}>
                  <MapIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleRedirect("/dashboard")}>
                  <DashboardIcon />
                </IconButton>
              )}
              <IconButton
                id='basic-button'
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup='true'
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <PermIdentityIcon />
              </IconButton>
              <Menu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Navbar;
