import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

function Footer() {
  return (
    <Box
      bgcolor='#0192CF'
      position='static'
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        p: 2,
      }}
    >
      <Grid container>
        <Grid container item xs={12} sm={12} md={4}>
          <Typography color='white'>
            Copyright © {new Date().getFullYear()} SALTO Systems, S.L. All
            rights reserved.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={4}></Grid>

        <Grid
          container
          item
          xs={12}
          sm={12}
          md={4}
          justifyContent='space-around'
        >
          <Grid item>
            <Typography color='white'>
              <a
                href='https://saltosystems.com/en/rd-projects/'
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: "none", color: "white" }}
              >
                R&D Projects
              </a>
            </Typography>
          </Grid>
          <Typography color='white'>|</Typography>
          <Grid item>
            <Typography color='white'>
              <a
                href='https://saltosystems.com/en/legal-data/'
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: "none", color: "white" }}
              >
                Legal
              </a>
            </Typography>
          </Grid>
          <Typography color='white'>|</Typography>
          <Grid item>
            <Typography color='white'>
              <a
                href='https://saltosystems.com/en/legal-data/website-terms-use/legal-terms/'
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: "none", color: "white" }}
              >
                Terms of Use
              </a>
            </Typography>
          </Grid>
          <Typography color='white'>|</Typography>
          <Grid item>
            <Typography color='white'>
              <a
                href='https://saltosystems.com/en/legal-data/privacy/privacy-policy/'
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: "none", color: "white" }}
              >
                Privacy Policy
              </a>
            </Typography>
          </Grid>
          <Typography color='white'>|</Typography>
          <Grid item>
            <Typography color='white'>
              <a
                href='https://saltosystems.com/en/legal-data/privacy/cookies-policy/'
                target='_blank'
                rel='noreferrer'
                style={{ textDecoration: "none", color: "white" }}
              >
                Cookies Policy
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Footer;
