const ROOT_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_HOST : "";

const BASE_ENDPOINT = `${ROOT_URL}/api/agents`;

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const createAgent = async payload => {
  const response = await fetch(`${BASE_ENDPOINT}/`, {
    ...config,
    method: "POST",
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (response.status === 400) throw new Error(data.message);
  return data;
};

export const getAgents = async () => {
  const response = await fetch(`${BASE_ENDPOINT}/`);
  const data = response.json();
  return data;
};

export const getAgentById = async (agentId, resourceName) => {
  const response = await fetch(
    `${BASE_ENDPOINT}/${agentId}?resourceName=${resourceName}`
  );
  const data = await response.json();
  return data;
};

export const getAgentsByCountry = async (
  countryId,
  resourceId = null,
  areaId = null
) => {
  const response = await fetch(
    `${BASE_ENDPOINT}/country/${countryId}?resourceId=${resourceId}${
      areaId ? `&areaId=${areaId}` : ""
    }`,
    config
  );
  const data = response.json();
  return data;
};

export const updateAgent = async (agentId, payload) => {
  const response = await fetch(`${BASE_ENDPOINT}/${agentId}`, {
    ...config,
    method: "PUT",
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (response.status === 400) throw new Error(data.message);
  return data;
};

export const downloadAgentVCard = async payload => {
  const response = await fetch(`${BASE_ENDPOINT}/download`, {
    ...config,
    method: "POST",
    body: JSON.stringify(payload),
  });
  const data = await response.blob();
  if (response.status === 400) throw new Error(data.message);
  return data;
};
