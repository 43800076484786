const ROOT_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_HOST : "";

const BASE_ENDPOINT = `${ROOT_URL}/api`;

export const login = async payload => {
  const response = await fetch(`${BASE_ENDPOINT}/login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (response.status === 400) throw new Error(data.message);
  return data;
};
