import {
  FormControl,
  Select,
  Chip,
  OutlinedInput,
  MenuItem,
  Box,
  InputLabel,
  FormHelperText,
} from "@mui/material";

function MultiSelect({
  items,
  label,
  selectedItems,
  handler,
  disabled,
  name,
  isCreating = false,
  labelId,
  error,
  helperText,
}) {
  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        name={name}
        displayEmpty
        multiple
        value={selectedItems}
        onChange={handler}
        disabled={disabled}
        input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
        label={label}
        labelId={labelId}
        renderValue={selected => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map(item => (
              <Chip
                key={isCreating ? item.value : item}
                label={isCreating ? item.label : item}
              />
            ))}
          </Box>
        )}
        inputProps={{ "aria-label": "label" }}
      >
        {items.map((item, index) => (
          <MenuItem key={index} value={item}>
            {isCreating ? item.label : item}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

export default MultiSelect;
