import { createContext, useContext, useEffect, useState } from "react";

const AreContext = createContext();

const AuthContext = createContext();

export function useArea() {
  return useContext(AreContext);
}

export function useAuth() {
  return useContext(AuthContext);
}

function Provider({ children }) {
  const [country, setCountry] = useState(1);
  const [area, setArea] = useState({});
  const [checkedAreas, setCheckedAreas] = useState([]);

  const toogleCountry = country => {
    setCountry(() => country);
  };

  const setAreaSelect = area => {
    setArea(() => area);
  };

  const setSelectedAreas = areas => {
    setCheckedAreas(() => areas);
  };

  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const token = localStorage["salto_maps_jwt"];
    return token && token.length > 0;
  });

  useEffect(() => {
    setIsAuthenticated(() => {
      const token = localStorage["salto_maps_jwt"];
      return token && token.length > 0;
    });
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      <AreContext.Provider
        value={{
          country,
          toogleCountry,
          area,
          setAreaSelect,
          checkedAreas,
          setSelectedAreas,
        }}
      >
        {children}
      </AreContext.Provider>
    </AuthContext.Provider>
  );
}

export default Provider;
