export const REQUIRED_FIELDS = ["firstName", "lastName", "email"];
export const OPTIONAL_FIELDS = [
  "mobilePhone",
  "officeLocation",
  "notes",
  "company",
  "phone",
  "website",
  "title",
];

export const mergeObjects = agentAreas => {
  const result = [];

  for (const agentArea of agentAreas) {
    if (!result.some(item => item.resource === agentArea?.resources[0]?.id)) {
      result.push({
        resource: agentArea?.resources[0]?.id,
        areas: [agentArea?.area?.name],
      });
    } else {
      let lastItem = result[result.length - 1];
      if (!lastItem.areas.some(areaName => areaName === agentArea?.area?.name))
        lastItem.areas.push(agentArea?.area?.name);
    }
  }
  return result[0];
};

export const downloadFile = (blob, fileName) => {
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.style = "display: none";
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName.toLowerCase();
  a.click();
  window.URL.revokeObjectURL(url);
};

export const formValidate = (form, resourceAreaFields) => {
  let valid = true;
  for (const input in form) {
    if (form[input].required) {
      form[input].error = form[input].value === "";
      valid = valid && !form[input].error;
    }
  }

  resourceAreaFields.forEach(resourceArea => {
    resourceArea.resourceError =
      resourceArea.resource === 0 || resourceArea.resource === "";
    resourceArea.areasError = !resourceArea.areas.length > 0;
    valid = valid && !resourceArea.resourceError && !resourceArea.areasError;
  });

  resourceAreaFields[0].error = true;

  return {
    valid,
    form: { ...form },
    validatedResourceAreaFields: [...resourceAreaFields],
  };
};

export const normalizeData = form => {
  let formData = {};
  for (let input in form) {
    formData[input] = form[input].value;
  }
  return formData;
};

export const formatFields = (fields, requiredFields, formData = null) => {
  let formattedFormData = {};

  if (formData) {
    for (let field in formData) {
      formattedFormData[field] = {
        value: formData[field],
        required: requiredFields.includes(field),
        error: false,
      };
    }
    return formattedFormData;
  }

  fields.forEach(field => {
    formattedFormData[field] = { value: "", required: false, error: false };
  });

  requiredFields.forEach(field => {
    formattedFormData[field] = { value: "", required: true, error: false };
  });

  return formattedFormData;
};
