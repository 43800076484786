import { useEffect, useState, useRef } from "react";


import Slider from "react-slick";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useMediaQuery } from "react-responsive";

import Slide from "./Slide";
import { useArea } from "../context/Provider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/index.css";

function CustomSlider({ items }) {
  const isDesktop = useMediaQuery({ query: "(min-width: 901px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const [currentSlide, setCurrentSlide] = useState(null);
  const { setSelectedAreas, area, country } = useArea();

  const settings = {
    infinite: items.length > 2,
    speed: 500,
    vertical: true,
    verticalSwiping: true,
    dots: false,
    slidesToShow: 2,
    focusOnSelect: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 900,
        settings: {
          dots: false,
          arrows: true,
          // arrows: false,
          swipeToSlide: true,
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 1,
          // centerMode: true,
          infinite: true,
        },
      },
    ],
  };

  const handleSlideSelection = (index, mapData) => {
    if(mapData.areaData){
      setCurrentSlide(index);
      setSelectedAreas(mapData["areaData"].map(({ area }) => area.areaLayer));  
    } else{
      setCurrentSlide(index);
      setSelectedAreas(mapData["agent_areas"].map(({ area }) => area.areaLayer));
    }
  };

  useEffect(() => {
    setCurrentSlide(null);
  }, [country, area]);

  return (
    <>
      {isMobile && (
        <div className="custom-slider">
          <Slider {...settings}>
            {items &&
              items.map((item, index) => (
                <Slide
                  key={item.id}
                  currentSlide={index === currentSlide}
                  slideData={item}
                  handleClick={() => handleSlideSelection(index, item)}
                />
              ))}
          </Slider>
        </div>
      )}
      {isDesktop && (
        <div className="custom-list">
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {items &&
              items.map((item, index) => (
                <ListItem key={index}>
                  <Slide
                    key={item.id}
                    currentSlide={index === currentSlide}
                    slideData={item}
                    handleClick={() => handleSlideSelection(index, item)}
                  />
                </ListItem>
              ))}
          </List>
        </div>
      )}
    </>
  );
}

export default CustomSlider;
