import { useEffect, useState } from "react";
import { VectorMap } from "@south-paw/react-vector-maps";
import Container from "@mui/material/Container";
import Tooltip from "./Tooltip";

import { useArea } from "../context/Provider";

import USMapData from "../libs/maps/usa.json";
import CANMapData from "../libs/maps/can.json";

const mapData = {
  1: USMapData,
  2: CANMapData,
};

function Map() {
  const { country, setAreaSelect, area, checkedAreas, setSelectedAreas } =
    useArea();
  const [countryData, setCountryData] = useState(USMapData);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [stateAbbreviation, setStateAbbreviation] = useState('');

  const onClick = ({ target }) => {
    setAreaSelect({
      name: target.attributes.name.value,
      code: target.attributes.id.value,
    });
    setSelectedAreas([target.attributes.id.value]);
  };

  const onMouseOver = e => {
    setStateAbbreviation(e.target.attributes.abbr.value);
  };

  const onMouseMove = e => {
    setTooltipPosition({ y: e.clientY + 10, x: e.clientX + 10 });
    setIsTooltipVisible(true);
  };

  const onMouseOut = () => {
    setIsTooltipVisible(false);
  };

  useEffect(() => {
    setCountryData(() => mapData[country]);
  }, [country]);

  useEffect(() => {
    if (!area) setSelectedAreas(area.map(selectedArea => selectedArea.code));
  }, [area]);

  return (
    <Container 
      sx={{ 
        padding: 0, pt:{md:12} , p: 2
      }}
    >
      <div className='map'>
        <VectorMap
          {...countryData}
          layerProps={{ onClick, onMouseOver, onMouseMove, onMouseOut }}
          checkedLayers={checkedAreas}
        />
      </div>
      <Tooltip isVisible={isTooltipVisible} position={tooltipPosition} abbr={stateAbbreviation} />
    </Container>
  );
}

export default Map;
