import { useCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";

import MultiSelect from "../components/MultiSelect";
import CustomModal from "../components/CustomModal";
import { getAreas } from "../services/areas";
import { getResourceCategories } from "../services/resources";
import Dropdown from "../components/Dropdown";
import { getAgentById, updateAgent } from "../services/agents";
import CustomSnackbar from "../components/CustomSnackbar";

import {
  mergeObjects,
  formValidate,
  formatFields,
  normalizeData,
  REQUIRED_FIELDS,
  OPTIONAL_FIELDS,
} from "../utils";

import EditIcon from "@mui/icons-material/Edit";

function ViewAgent({ handleClose, open, agentId, resourceName }) {
  const [openSnack, setOpenSnack] = useState(false);
  const [notification, setNotification] = useState(null);
  const initialFormState = formatFields(OPTIONAL_FIELDS, REQUIRED_FIELDS);

  const initialResourceAreaFieldsState = {
    resource: 0,
    areas: [],
    resourceError: false,
    areasError: false,
  };

  const [formData, setFormData] = useState(initialFormState);
  const [areas, setAreas] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);

  const [resourceOptions, setResourceOptions] = useState([]);

  const [editing, setEditing] = useState(false);

  const [resourceAreaField, setResourceAreaField] = useState(
    initialResourceAreaFieldsState
  );

  const getData = useCallback(async () => {
    const resources = await getResourceCategories();
    setResourceOptions(() =>
      resources.map(resourceOption => ({
        label: resourceOption.category,
        value: resourceOption.id,
      }))
    );

    const data = await getAreas();
    setAreas(() => data);
    setAreaOptions(() => areas.map(area => area.name));
  }, [agentId]);

  const getAgentData = useCallback(async () => {
    if (!agentId || !resourceName) return;
    const agentData = await getAgentById(agentId, resourceName);
    setFormData(formatFields(OPTIONAL_FIELDS, REQUIRED_FIELDS, agentData));
    setResourceAreaField(() => ({
      ...resourceAreaField,
      ...mergeObjects(agentData["agent_areas"]),
    }));
  }, [agentId, resourceName]);

  const handleChange = e => {
    const formInput = {
      value: e.target.value,
      required: e.target.hasAttribute("required"),
      error: false,
    };
    setFormData(() => ({ ...formData, [e.target.id]: formInput }));
  };

  const handleSubmit = async () => {
    try {
      const { valid, form, validatedResourceAreaFields } = formValidate(
        formData,
        [resourceAreaField]
      );
      setFormData(form);
      setResourceAreaField(validatedResourceAreaFields[0]);
      if (!valid) return;
      const normalizedData = normalizeData(formData);
      const selectedAreas = resourceAreaField.areas.map(
        resourceArea => areas.find(area => area.name === resourceArea)?.id
      );
      const { message } = await updateAgent(agentId, {
        formData: normalizedData,
        selectedAreas,
        resourceId: resourceAreaField.resource,
      });
      setNotification(() => ({ type: "success", message }));
      setOpenSnack(true);
      handleRedirect();
    } catch (error) {
      setNotification(() => ({
        type: "error",
        message: "Email already taken",
      }));
      setOpenSnack(true);
    }
  };

  const handleRedirect = () => {
    setEditing(false);
    handleClose();
    getAgentData();
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  const handleAreaResourceSelect = e => {
    let areaResourceSelect = { ...resourceAreaField };
    areaResourceSelect[e.target.name] = e.target.value;
    setResourceAreaField(areaResourceSelect);
  };

  useEffect(() => {
    getData();
    getAgentData();
  }, [getData, getAgentData]);

  return (
    <>
      {notification && (
        <CustomSnackbar
          type={notification.type}
          open={openSnack}
          message={notification.message}
          handleClose={handleSnackClose}
        />
      )}
      <CustomModal handleClose={handleClose} open={open}>
        <Grid container>
          <Box
            display='flex'
            width={{ md: "50%", xs: "100%" }}
            justifyContent={{ md: "start", xs: "center" }}
          >
            <Typography
              id='modal-modal-title'
              variant='h5'
              component='h2'
              color='#1D4D64'
              fontWeight='500'
            >
              {editing ? "Edit Agent" : "View Agent"}
            </Typography>
          </Box>
          <Box
            display='flex'
            width={{ md: "50%", xs: "100%" }}
            justifyContent={{ md: "end", xs: "center" }}
          >
            <EditIcon
              sx={{ color: "#1D4D64" }}
              cursor='pointer'
              onClick={() => setEditing(!editing)}
            ></EditIcon>
          </Box>
        </Grid>
        <Divider />
        <Grid id='modal-modal-description' sx={{ mt: 2 }} container spacing={2}>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='firstName'
                label='First Name'
                value={formData.firstName.value || ""}
                variant='outlined'
                disabled={!editing}
                onChange={handleChange}
                required
                error={formData.firstName.error}
                helperText={
                  formData.firstName.error ? "Enter your first name" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='lastName'
                label='Last Name'
                value={formData.lastName.value || ""}
                variant='outlined'
                disabled={!editing}
                onChange={handleChange}
                required
                error={formData.lastName.error}
                helperText={
                  formData.lastName.error ? "Enter your last name" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='email'
                label='Email'
                value={formData.email.value || ""}
                variant='outlined'
                disabled={!editing}
                onChange={handleChange}
                required
                error={formData.email.error}
                helperText={formData.email.error ? "Enter your email" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='mobilePhone'
                label='Mobile Phone'
                value={formData.mobilePhone.value || ""}
                variant='outlined'
                disabled={!editing}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "100%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='title'
                label='Title'
                variant='outlined'
                value={formData.title.value || ""}
                disabled={!editing}
                onChange={handleChange}
                error={formData.title.error}
                helperText={formData.title.error ? "Enter title" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "100%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='company'
                label='Company'
                value={formData.company.value || ""}
                variant='outlined'
                disabled={!editing}
                onChange={handleChange}
                error={formData.company.error}
                helperText={
                  formData.company.error ? "Enter the name of the company" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='officeLocation'
                label='Office Location'
                variant='outlined'
                value={formData.officeLocation.value || ""}
                disabled={!editing}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='phone'
                label='Phone'
                variant='outlined'
                value={formData.phone.value || ""}
                disabled={!editing}
                onChange={handleChange}
                error={formData.phone.error}
                helperText={
                  formData.phone.error ? "Enter your office number" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "100%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='website'
                label='Website'
                variant='outlined'
                value={formData.website.value || ""}
                disabled={!editing}
                onChange={handleChange}
                error={formData.website.error}
                helperText={formData.website.error ? "Enter website" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "100%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='notes'
                label='Notes'
                variant='outlined'
                value={formData.notes.value || ""}
                disabled={!editing}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <Dropdown
              handler={handleAreaResourceSelect}
              value={resourceAreaField.resource || ""}
              items={resourceOptions}
              variant='outlined'
              rest={{
                disabled: true,
                error: resourceAreaField.resourceError,
              }}
              label='Resource category'
              labelId='resource-category'
              internalProps={{ name: "resource" }}
              helperText='Select a resource'
            />
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }} className='font'>
            <MultiSelect
              name='areas'
              items={areaOptions}
              label='Select Areas'
              labelId='areas'
              selectedItems={resourceAreaField.areas || []}
              handler={handleAreaResourceSelect}
              disabled={!editing}
              error={resourceAreaField.areasError}
              helperText='Select areas'
            />
          </Grid>
        </Grid>
        <Box
          display='flex'
          justifyContent={{ md: "end", xs: "center" }}
          marginTop={4}
        >
          <Box display='flex' justifyContent='space-between'>
            <Button
              type='submit'
              sx={{
                backgroundColor: "#0192CF",
                color: "white",
                fontSize: "14px",
                padding: "10px 30px 10px 30px",
                borderRadius: "10px",
                marginLeft: "10px",
              }}
              onClick={handleSubmit}
            >
              <Typography color='white' fontSize='16px'>
                Save
              </Typography>
            </Button>
            <Button
              sx={{
                backgroundColor: "#1D4D64",
                color: "white",
                fontSize: "14px",
                padding: "10px 40px 10px 40px",
                borderRadius: "10px",
                marginLeft: "15px",
              }}
              onClick={handleRedirect}
            >
              <Typography color='white' fontSize='16px'>
                Cancel
              </Typography>
            </Button>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}
export default ViewAgent;
