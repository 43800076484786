import { useEffect, useState, Fragment } from "react";
import { Typography, IconButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import MultiSelect from "../components/MultiSelect";
import CustomModal from "../components/CustomModal";
import { createAgentArea, getAreas } from "../services/areas";
import {
  createResourceArea,
  getResourceCategories,
} from "../services/resources";
import Dropdown from "../components/Dropdown";
import { createAgent } from "../services/agents";
import CustomSnackbar from "../components/CustomSnackbar";
import {
  formValidate,
  normalizeData,
  formatFields,
  OPTIONAL_FIELDS,
  REQUIRED_FIELDS,
} from "../utils";

function CreateAgent({ handleClose, open }) {
  const initialFormState = formatFields(OPTIONAL_FIELDS, REQUIRED_FIELDS);

  const initialResourceAreaFieldsState = [
    {
      resource: 0,
      areas: [],
      resourceError: false,
      areasError: false,
    },
  ];
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [notification, setNotification] = useState({});
  const [formData, setFormData] = useState(initialFormState);
  const [areaOptions, setAreaOptions] = useState([]);

  const [resourceOptions, setResourceOptions] = useState([]);

  const [resourceAreaFields, setResourceAreaFields] = useState(
    initialResourceAreaFieldsState
  );

  const getData = async () => {
    const resources = await getResourceCategories();
    setResourceOptions(() =>
      resources.map(resourceOption => ({
        label: resourceOption.category,
        value: resourceOption.id,
      }))
    );

    const areas = await getAreas();
    setAreaOptions(() =>
      areas.map(area => ({ value: area.id, label: area.name }))
    );
  };

  const handleChange = e => {
    const formInput = {
      value: e.target.value,
      required: e.target.hasAttribute("required"),
      error: false,
    };
    setFormData(() => ({ ...formData, [e.target.id]: formInput }));
  };

  const handleAreaResourceSelect = (index, e) => {
    let areaResourceSelect = [...resourceAreaFields];
    areaResourceSelect[index][e.target.name] = e.target.value;
    areaResourceSelect[index][`${e.target.name}Error`] = false;
    setResourceAreaFields(areaResourceSelect);
  };

  const handleRedirect = () => {
    setFormData(initialFormState);
    setResourceAreaFields(initialResourceAreaFieldsState);
    handleClose();
  };

  const handleSubmit = async () => {
    try {
      const { valid, form, validatedResourceAreaFields } = formValidate(
        formData,
        resourceAreaFields
      );
      setFormData(form);
      setResourceAreaFields(validatedResourceAreaFields);
      if (!valid) return;
      const normalizedData = normalizeData(formData);
      const { id: agentId } = await createAgent(normalizedData);
      for (const { areas, resource } of resourceAreaFields) {
        const data = await createAgentArea({
          areaIdList: areas.map(selectArea => selectArea.value),
          agentId,
        });
        await createResourceArea({
          agentAreaIdList: data.map(agentArea => agentArea.id),
          resourceId: resource,
        });
      }
      setNotification({
        type: "success",
        message: "Agent created Successfully",
      });
      setOpenSnackBar(true);
      handleRedirect();
    } catch (error) {
      setNotification({ type: "error", message: "Email already taken" });
      setOpenSnackBar(true);
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar(false);
  };

  // const addResourceAreaField = () => {
  //   setResourceAreaFields([...resourceAreaFields, { resource: 0, areas: [] }]);
  // };

  const removeFormFields = index => {
    let newResourceAreaFields = [...resourceAreaFields];
    newResourceAreaFields.splice(index, 1);
    setResourceAreaFields(newResourceAreaFields);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {notification && (
        <CustomSnackbar
          type={notification.type}
          open={openSnackBar}
          message={notification.message}
          handleClose={handleSnackClose}
        />
      )}
      <CustomModal handleClose={handleClose} open={open}>
        <Typography
          id='modal-modal-title'
          variant='h5'
          component='h2'
          color='#1D4D64'
          fontWeight='500'
        >
          Create a New Agent
        </Typography>
        <Divider />
        <Grid id='modal-modal-description' sx={{ mt: 2 }} container spacing={2}>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='firstName'
                label='First Name'
                value={formData.firstName.value}
                variant='outlined'
                onChange={handleChange}
                required
                error={formData.firstName.error}
                helperText={
                  formData.firstName.error ? "Enter your first name" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='lastName'
                label='Last Name'
                value={formData.lastName.value}
                variant='outlined'
                onChange={handleChange}
                required
                error={formData.lastName.error}
                helperText={
                  formData.lastName.error ? "Enter your last name" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='email'
                label='Email'
                value={formData.email.value}
                variant='outlined'
                onChange={handleChange}
                required
                error={formData.email.error}
                helperText={formData.email.error ? "Enter your email" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='mobilePhone'
                label='Mobile Phone'
                value={formData.mobilePhone.value}
                variant='outlined'
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "100%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='title'
                label='Title'
                variant='outlined'
                value={formData.title.value}
                onChange={handleChange}
                error={formData.title.error}
                helperText={formData.title.error ? "Enter title" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "100%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='company'
                label='Company'
                value={formData.company.value}
                variant='outlined'
                onChange={handleChange}
                error={formData.company.error}
                helperText={
                  formData.company.error ? "Enter the name of the company" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='officeLocation'
                label='Office Location'
                variant='outlined'
                value={formData.officeLocation.value}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "50%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='phone'
                label='Office number'
                variant='outlined'
                value={formData.phone.value}
                onChange={handleChange}
                error={formData.phone.error}
                helperText={
                  formData.phone.error ? "Enter your office number" : ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "100%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='website'
                label='Website'
                variant='outlined'
                value={formData.website.value}
                onChange={handleChange}
                error={formData.website.error}
                helperText={formData.website.error ? "Enter website" : ""}
              />
            </FormControl>
          </Grid>
          <Grid item width={{ md: "100%", xs: "100%" }}>
            <FormControl fullWidth>
              <TextField
                id='notes'
                label='Notes'
                multiline
                minRows={3}
                variant='outlined'
                value={formData.notes.value}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          {resourceAreaFields &&
            resourceAreaFields.map((resourceAreaField, index) => (
              <Fragment key={index}>
                <Grid item width={{ md: "45%", xs: "100%" }}>
                  <Dropdown
                    handler={e => handleAreaResourceSelect(index, e)}
                    value={resourceAreaField.resource || ""}
                    items={resourceOptions}
                    variant='outlined'
                    label='Resource category'
                    labelId='resource-category'
                    rest={{ error: resourceAreaField.resourceError }}
                    internalProps={{ name: "resource" }}
                    helperText='Select a resource'
                  />
                </Grid>
                <Grid item width={{ md: "45%", xs: "100%" }} className='font'>
                  <MultiSelect
                    isCreating
                    name='areas'
                    items={areaOptions}
                    label='Areas'
                    labelId='areas'
                    selectedItems={resourceAreaField.areas || []}
                    handler={e => handleAreaResourceSelect(index, e)}
                    error={resourceAreaField.areasError}
                    helperText='Select areas'
                  />
                </Grid>
                <Grid
                  item
                  width={{ md: "10%", xs: "100%" }}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                >
                  <IconButton
                    disabled={index === 0}
                    onClick={() => removeFormFields(index)}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Grid>
              </Fragment>
            ))}
        </Grid>
        {/* <Button
          fullWidth
          onClick={addResourceAreaField}
          disableRipple
          sx={{
            marginY: 3,
            justifyContent: "start",
          }}
        >
          <AddIcon sx={{ color: "#718792" }} />
          Add new category
        </Button> */}
        <Box
          display='flex'
          justifyContent={{ md: "end", xs: "center" }}
          marginY={3}
        >
          <Button
            type='submit'
            sx={{
              backgroundColor: "#0192CF",
              color: "white",
              fontSize: "14px",
              padding: "10px 25px 10px 25px",
              borderRadius: "10px",
            }}
            onClick={handleSubmit}
          >
            <AddIcon
              sx={{
                paddingRight: "5px",
              }}
            ></AddIcon>
            <Typography color='white' fontSize='16px'>
              Create Agent
            </Typography>
          </Button>
          <Button
            sx={{
              backgroundColor: "#1D4D64",
              color: "white",
              fontSize: "14px",
              padding: "10px 30px 10px 30px",
              borderRadius: "10px",
              marginLeft: "15px",
            }}
            onClick={handleRedirect}
          >
            <Typography color='white' fontSize='16px'>
              Cancel
            </Typography>
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}
export default CreateAgent;
