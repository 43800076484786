import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function CustomSnackbar({ open, handleClose, type, message }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <MuiAlert
        onClose={handleClose}
        severity={type}
        sx={{ width: "100%" }}
        elevation={6}
        variant='filled'
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
}

export default CustomSnackbar;
