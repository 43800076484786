const ROOT_URL =
  process.env.NODE_ENV === "production" ? process.env.REACT_APP_API_HOST : "";

const BASE_ENDPOINT = `${ROOT_URL}/api/resources`;

const config = {
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
};

export const getResourceCategories = async () => {
  const response = await fetch(`${BASE_ENDPOINT}/`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  const data = response.json();
  return data;
};

export const createResourceArea = async payload => {
  const response = await fetch(`${BASE_ENDPOINT}/areas`, {
    ...config,
    method: "POST",
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  return data;
};

export const updateResourceAreaStatus = async payload => {
  const response = await fetch(`${BASE_ENDPOINT}/areas`, {
    ...config,
    method: "PUT",
    body: JSON.stringify(payload),
  });
  const data = await response.json();
  if (response.status === 400) throw new Error(data.message);
  return data;
};
