import { Box } from "@mui/material";


function Tooltip({ isVisible, position, abbr }) {
  return (
    <Box
      position='absolute'
      padding='10px'
      border='1px solid #ccc'
      borderRadius='15px'
      display={isVisible ? "block" : "none"}
      top={position.y}
      left={position.x}
      fontFamily='Roboto'
      sx={{
        backgroundColor: 'white'
      }}
    >
      {abbr}
    </Box>
  );
}

export default Tooltip;
