import { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

import CreateAgent from "../views/CreateAgent";
import ViewAgent from "../views/ViewAgent";

import AddIcon from "@mui/icons-material/Add";
import NotInterestedOutlinedIcon from "@mui/icons-material/NotInterestedOutlined";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Typography from "@mui/material/Typography";

import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { Container } from "@mui/system";
import CustomSnackbar from "../components/CustomSnackbar";

import { getAgents } from "../services/agents";
import { updateResourceAreaStatus } from "../services/resources";

function Dashboard() {
  const [selectionModel, setSelectionModel] = useState([]);
  const [resources, setResources] = useState([]);
  const [resourceAreasIds, setResourceAreasIds] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const [agentId, setAgentId] = useState();
  const [resourceName, setResourceName] = useState();

  const [notification, setNotification] = useState(null);
  const [openSnack, setOpenSnack] = useState(false);

  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "name",
      headerName: "Full Name",
      width: 200,
      filtrable: true,
      valueGetter: params => `${params.row.firstName} ${params.row.lastName}`,
    },
    {
      field: "company",
      headerName: "Company Name",
      width: 200,
    },
    {
      field: "phone",
      headerName: "Mobile Phone",
      width: 200,
    },
    {
      field: "category",
      headerName: "Resource Category",
      filtrable: true,
      width: 200,
    },
    {
      field: "country",
      headerName: "Country",
      width: 200,
      valueGetter: params => [...new Set(params.row.countries)].join(", "),
    },
    {
      field: "area",
      headerName: "Areas",
      width: 360,
      valueGetter: params => [...new Set(params.row.areas)].join(", "),
    },
    {
      field: "status",
      headerName: "Status",
      width: 130,
      valueGetter: params => (params.row.status ? "Active" : "Inactive"),
    },
    {
      headerName: "Options",
      field: "actions",
      type: "actions",
      getActions: params => [
        <GridActionsCellItem
          icon={<RemoveRedEyeIcon />}
          onClick={() => handleViewModal(params)}
          label='View'
          showInMenu
        />,
      ],
    },
  ];

  const getData = async () => {
    const agentsData = await getAgents();
    setResources(() => agentsData);
  };

  const handleViewModal = params => {
    setAgentId(params.row.agentId);
    setResourceName(params.row.category);
    setOpenEditModal(true);
  };

  const handleToogleResourceAreaStatus = async () => {
    try {
      const { message } = await updateResourceAreaStatus({
        resourceAreasIds,
        status: !isActive,
      });
      setIsActive(prev => !prev);
      setNotification(() => ({ type: "success", message }));
      setOpenSnack(true);
      getData();
    } catch (error) {
      setNotification(() => ({ type: "error", message: error.message }));
      setOpenSnack(true);
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };

  useEffect(() => {
    getData();
  }, [openCreateModal, openEditModal]);

  useEffect(() => {
    if (!selectionModel.length > 0) return;
    const selectedResource = resources.find(
      resource => resource.id === selectionModel[0]
    );
    setResourceAreasIds(selectedResource?.resourceIds);
    setIsActive(selectedResource?.status);
  }, [selectionModel]);

  return (
    <>
      {notification && (
        <CustomSnackbar
          type={notification.type}
          open={openSnack}
          message={notification.message}
          handleClose={handleSnackClose}
        />
      )}
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        <Navbar />
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            backgroundColor: "#F5F5F5",
          }}
          flexDirection='column'
        >
          <Box display='flex' padding={3} justifyContent='space-between'>
            <Grid container>
              <Grid item width={{ xs: "100%", md: "50%" }}>
                <Box
                  display='flex'
                  flexGrow={1}
                  justifyContent={{ xs: "space-around", md: "flex-start" }}
                  paddingLeft={{ xs: "0px", md: "20px" }}
                >
                  <Typography color='#1D4D64' fontSize='30px' fontWeight='500'>
                    Agents
                  </Typography>
                </Box>
              </Grid>
              <Grid item width={{ xs: "100%", md: "50%" }}>
                <Box
                  display='flex'
                  flexGrow={1}
                  justifyContent={{ xs: "space-around", md: "flex-end" }}
                >
                  <Box
                    display='flex'
                    justifyContent={{ xs: "space-around", md: "flex-end" }}
                    flexGrow={0.5}
                  >
                    <Button
                      disabled={selectionModel.length < 1}
                      sx={{
                        backgroundColor:
                          selectionModel.length < 1
                            ? "rgba(1, 146, 207, 0.29)"
                            : "#0192CF",
                        color: "white",
                        fontSize: "14px",
                        padding: "10px 20px 10px 20px",
                        borderRadius: "10px",
                      }}
                      onClick={handleToogleResourceAreaStatus}
                    >
                      {isActive ? (
                        <>
                          <NotInterestedOutlinedIcon
                            sx={{
                              paddingRight: "5px",
                            }}
                          ></NotInterestedOutlinedIcon>
                          <Typography color='white' fontSize='16px'>
                            Inactive
                          </Typography>
                        </>
                      ) : (
                        <Typography color='white' fontSize='16px'>
                          Active
                        </Typography>
                      )}
                    </Button>
                    <Button
                      sx={{
                        backgroundColor: "#0192CF",
                        color: "white",
                        fontSize: "14px",
                        padding: "10px 20px 10px 20px",
                        borderRadius: "10px",
                        marginLeft: "10%",
                      }}
                      onClick={() => setOpenCreateModal(true)}
                    >
                      <AddIcon
                        sx={{
                          paddingRight: "5px",
                        }}
                      ></AddIcon>
                      <Typography color='white' fontSize='16px'>
                        Create Agent
                      </Typography>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Container
            sx={{
              height: "70vh",
              margin: "",
              padding: "0px 0px 48px 0px",
              letterSpacing: "none",
            }}
            maxWidth={{ md: "100%!important", xs: "100%" }}
          >
            <DataGrid
              rows={resources}
              columns={columns}
              rowsPerPageOptions={[10, 25, 50, 100, resources.length]}
              pagination
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0px 7px 22px rgba(152, 177, 188, 0.19)",
                borderRadius: "13px",
                padding: "18px",
              }}
              selectionModel={selectionModel}
              hideFooterSelectedRowCount
              onSelectionModelChange={selection => {
                if (selection.length > 1) {
                  const selectionSet = new Set(selectionModel);
                  const result = selection.filter(s => !selectionSet.has(s));

                  setSelectionModel(result);
                } else {
                  setSelectionModel(selection);
                }
              }}
            />
          </Container>
        </Box>
        <Footer />
        <CreateAgent
          open={openCreateModal}
          handleClose={() => setOpenCreateModal(false)}
        />
        <ViewAgent
          open={openEditModal}
          handleClose={() => setOpenEditModal(false)}
          agentId={agentId}
          resourceName={resourceName}
        />
      </div>
    </>
  );
}

export default Dashboard;
