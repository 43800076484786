import React from "react";

import {
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  FormHelperText,
} from "@mui/material";

const Dropdown = ({
  value,
  items,
  handler,
  rest,
  variant = "filled",
  label = "Select value",
  labelId,
  internalProps,
  helperText,
}) => {
  return (
    <FormControl variant={variant} fullWidth {...rest}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select value={value} onChange={handler} label={label} {...internalProps}>
        {items &&
          items.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
      </Select>
      {rest && rest.error && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Dropdown;
